@import '../../../App.module.css';

.menuLink {
  color: var(--color1);
  font-family: var(--fraunces);
  font-size: small;
  font-weight: 750;
}

.headerEl {
  width: 88vw;
  margin: 2.5vh 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.linkEl {
  display: flex;
  flex-direction: row;
}

.menuLink {
  font-family: var(--headerFont);
  font-size: calc(2.5px + 2vmin);
  color: var(--color11);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0em .5em;
}

.activeLink {
  text-decoration: underline;
}

.nameEl {
  color: var(--color11);
  font-family: 'Sen', sans-serif;
  font-size: calc(.25px + 1vmin);
  font-weight: 600;
  text-transform: lowercase;
  text-decoration: none;

}


 