.cardContainer {
  width: 100%;
  display: flex;
  min-width: fit-content;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid black; */
  margin: 05% 0%;
  margin-top: 10%;
  padding-bottom: 20px;
}

.cardContent{
  width: 15em;
  /* padding: 0; */
}

.cardContent >h2 {
  font-size: calc(11px + 2vmin);
  margin: 0;
  font-family: var(--monoFont);
}

.cardContent >p {
  font-size: calc(1px + 2vmin);
  font-family: var(--monoFont);
}

.cardLinks {
  display: flex;
  flex-direction: column;
  width: 55%;
  
}

.cardLinks >a {
  transition: all 0.3s;
  transform: scale(1, 1);
  color: black;
  text-decoration: none;
  background-position-y: -0%;
  background-image: linear-gradient( rgba(255, 255, 255, 0) 50%, var(--color8) 50%);
  transition: background 500ms ease;
  background-size: 2px;
  background-size: auto 195%;
  padding: 25% 0% 0% 1.5%;
  font-family: var(--headerFont);
  font-size: calc(6px + 2vmin);
  color: var(--color2);
}

.cardLinks >a:hover {
  background-position-y: 100%;
  color: var(--color5)
}


.cardContainer >img {
  margin-left: 5%;
  border: var(--color11) 1px solid;
  border-radius: 2em;
  box-shadow: var(--color11) 10px 10px;
  display: block;
  width: 60%;
  height: auto;
  opacity: 65%;
}

.cardContainer >img:hover {
  opacity: 100%;
}

