@import url('https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

@font-face {
  font-family: 'Yipes';
  font-style: normal;
  src: 
      url('./assets/fonts/Yipes/Yipes.ttf'),
      url('./assets/fonts/Yipes/yipes-webfont.woff2') format('woff2'),
      url('./assets/fonts/Yipes/yipes-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yipes Italic';
  src: 
      url('./assets/fonts/Yipes/Italic/YipesItalic.ttf'),
      url('./assets/fonts/Yipes/Italic/yipesitalic-webfont.woff2') format('woff2'),
      url('./assets/fonts/Yipes/Italic/yipesitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Grifter';
  font-style: bold;
  src:
      url('./assets/fonts/grifter/grifterbold.otf') format('OneType'),
      url('./assets/fonts/grifter/grifterbold-webfont.woff2') format('woff2'),
      url('./assets/fonts/grifter/grifterbold-webfont.woff') format('woff');
  font-weight: normal;
}

:root {
  --headerColor: #cbc0d3d0;
  --color1: #262626;
  --color11: #3e2b55;
  --color12: #8a7e99;
  --color2: #DEE2FF;
  --color3: #FEEAFA;
  --color4: #EFD3D7;
  --color5: #DDCAD5;
  --color6: #CBC0D3;
  --color7: #8E9AAF;
  --color8: #7c814b;
  --headerFont: 'Sen', sans-serif;
  --monoFont: 'Fahkwang';
  --displayFont: 'Aclonica', sans-serif;
  --displayFontItalic: 'Aclonica', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

html {
  min-height:100vh;
  height:100vh;
  width: 100vw;
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: 1200% 1200%;
  background: #e8cbc0;
  background: -webkit-radial-gradient(ellipse at right bottom, #ecdad4, #8c94b7);
  background: radial-gradient(ellipse at right bottom, #ecdad4, #8c94b7);
  background-repeat: no-repeat;
  color: var(--color11);
  background-attachment: fixed;
 }

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 5%;
}

body > footer {
  position: absolute;
  top: 100vh;
}

h2 {
  margin: 0;
  padding: 0;
  font-family: var(--displayFont);
  font-size: calc(40px + 2vmin);
  margin-top: 2em;
  height: fit-content;
  padding: 2px;
  padding-top: 10px;
}

.container {
  padding-bottom: 5%;
}

.homeContainer {
  width: 88vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* border: #262626 solid 1px; */
}

.homeText {
  margin-top: 15px;
  padding: .5em;
  border-radius: .55em;
  font-family: var(--displayFont);
}

.homeText >h1 {
  font-family: var(--displayFont);
  font-weight: 600;
  font-size: calc(60px + 2vmin); 
  text-transform: capitalize;
  word-wrap: break-word;
  text-indent: -.5;
}
/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  { 

  .homeText >h1 {
    font-size: calc(100px + 2vmin); 
    line-height: 150%;
  }

  .homeBody >p {
    font-size: calc(15px + 2vmin);
    line-height: 60px;

  }
  

 }


.homeBody {
  font-family: var(--headerFont);
  line-height: 35px;
}

.homeBody > p:last-child {
  color: var(--color8);
}
.homeText >h1:nth-child(2) {
  text-indent: -.25em;
  padding-left: .25em;
}

.meEl {
  display: flex;
  flex-direction: column;
  font-family: var(--monoFont);
  font-size: calc(4px + 2vmin);
  line-height: calc(20px + 2vmin);
  padding: 2em;
  align-items: flex-end;
  width: 88vw;
  height: 85vh;
  margin-bottom: 25%;
  margin-top: 10%;
}

.meEl >p{
  margin-top: 8vw;
  /* border: var(--color11) 1px solid; */
  padding: 2em;
  text-align: right;
}

.skillsEl{
  width: 90vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8vw;
  justify-content: space-between;
}

span >p {
  width: fit-content;
  min-width: 2.5em;
  list-style-type: none;
  padding: .55em;
  border: var(--color11) solid 2px;
  background-color: var(--color12);
  border-radius: 2em;
  text-align: center;
  font-size: calc(7.5px + 1vmin);
  margin: 2px;
}

span >p:hover {
  color: var(--color2);
  background-color: var(--color11);
}

/* projects */

.projectContainer {
  width: 88vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 25%;
}

.projectContainer >h2 {
  text-align: right;
}

.contactContainer {
 display: flex;
 flex-direction: column;
 font-family: var(--monoFont);
 font-size: calc(15px + 5vmin);
 width: 88vw;
 height: 85vh;
 justify-content: space-around;
}

.contactBody {
  display: flex;
 flex-direction: column;
  justify-content: space-around;
}

.contactBody >p {
  text-align: right;
}

.contactBody >p>a {
  margin: 0px 20px 0px 20px;
  font-family: var(--headerFont);
}

.contactBody >p>a, .contactBody >p>a:active, .contactBody >p>a:visited, .contactBody >p>a:link {
 text-decoration: none;
 color: #7c814b;
}


/* animation for background */

@keyframes gradient {
  0%{background-position:43% 0%}
  50%{background-position:58% 100%}
  100%{background-position:43% 0%}
}
